import Hero from 'components/hero';
import Layout from 'components/layout';
import StickyMenu from 'components/sticky-menu';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

// markup
const NotFoundPage = ({location}) => {
  const {t} = useTranslation();
  
  return (
    <Layout path={location.pathname}>
      <StickyMenu pageSlug={location.pathname} visible />
      <div className="mb-48">
        <Hero 
          title={(
          <>
            <h1 className="mb-0 lowercase font-bold">404</h1>
            <h3 className="styled w-ligature">{t('Page not found')}</h3>
          </>
        )}/>
      </div>
    </Layout>
  )
};

export const pageQuery = graphql`
  query Notfoundpage($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default NotFoundPage;
